import React, { useReducer } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import axios from "axios";

import formReducer from "../reducers/FormReducer";

let initialTestAndTagScheduleFormState = {
  contactPerson: "",
  phoneNumber: "",
  emailAddress: "",
  equipmentToBeServiced: "",
  dateOfRequest: "",
};

const TestAndTagModal = (props) => {
  let [state, dispatch] = useReducer(
    formReducer,
    initialTestAndTagScheduleFormState
  );

  const handleTextChange = (event) => {
    dispatch({
      type: "HANDLE REQUEST TEST AND TAG FORM INPUT",
      field: event.target.name,
      payload: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    let data = state;
    //console.log(data);
    sendContactForm(data);
    event.preventDefault();
  };

  const sendContactForm = (data) => {
    axios
      .post("https://mailer.ciscientific.com/ctat-test-and-tag", data)
      .then((response) => {
        let result = response.data;
        if (
          result.status === "Message Sent" ||
          result.status === "Message Recieved"
        ) {
          console.log("Email Sent!");
          props.handleClose();
          //props.notifyToastSuccess();
          props.handleModalPopup();
          props.handleMessageStatus(true);
          formReset();
        } else {
          console.log("Error");
          //props.notifyToastFailed();
          props.handleModalPopup();
          props.handleMessageStatus(false);
        }
        //console.log(result.data);
      });
  };

  // RESET FORM FUNCTION
  const formReset = () => {
    dispatch({ type: "HANDLE FORM RESET" });
  };

  return (
    <div className="test-and-tag-modal-container">
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="test-and-tag-main-modal"
        method="POST"
        onSubmit={handleFormSubmit}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="test-and-tag-header-container"
          style={{ backgroundColor: "#0b0b0b", backdropFilter: "blur(10px)" }}
        >
          <Modal.Title
            className="test-and-tag-header-text"
            style={{ fontSize: "1.2rem", fontWeight: "400", color: "#FFFFFF" }}
          >
            Book your Testing and Tagging Schedule
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="test-and-tag-body">
          <Form className="test-and-tag-form-container">
            <Form.Group
              className="mb-3 test-and-tag-form-group"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label
                className="test-and-tag-form-label"
                style={{ display: "none" }}
              >
                Contact Person
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Person's Full Name"
                autoFocus
                className="test-and-tag-form-control"
                required
                name="contactPerson"
                value={state.contactPerson}
                onChange={handleTextChange}
              />
            </Form.Group>

            <Form.Group
              className="mb-3 test-and-tag-form-group"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label
                className="test-and-tag-form-label"
                style={{ display: "none" }}
              >
                Phone Number
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Phone Number"
                className="test-and-tag-form-control"
                required
                name="phoneNumber"
                value={state.phoneNumber}
                onChange={handleTextChange}
              />
            </Form.Group>

            <Form.Group
              className="mb-3 test-and-tag-form-group"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label
                className="test-and-tag-form-label"
                style={{ display: "none" }}
              >
                Email address
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
                className="test-and-tag-form-control"
                required
                name="emailAddress"
                value={state.emailAddress}
                onChange={handleTextChange}
              />
            </Form.Group>

            <Form.Group
              className="mb-3 test-and-tag-form-group"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label
                className="test-and-tag-form-label"
                style={{ display: "none", resize: "none" }}
              >
                Equipment to be Serviced
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Equipment to be Serviced"
                rows={5}
                className="test-and-tag-form-control"
                required
                name="equipmentToBeServiced"
                value={state.equipmentToBeServiced}
                onChange={handleTextChange}
              />
            </Form.Group>

            <Form.Group
              className="mb-3 test-and-tag-form-group"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="test-and-tag-form-label">
                Select date of service
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Select date of service"
                className="test-and-tag-form-control"
                required
                name="dateOfRequest"
                value={state.dateOfRequest}
                onChange={handleTextChange}
              />
            </Form.Group>

            <Modal.Footer className="test-and-tag-modal-footer">
              <Button
                variant="outline-primary"
                className="test-and-tag-modal-footer-buttons"
                id="book-schedule-btn"
                style={{
                  borderRadius: "0",
                  border: "2px solid",
                  textTransform: "uppercase",
                }}
                name="submit"
                value="submitRequest"
                type="submit"
              >
                Book Schedule
              </Button>
              <Button
                variant="outline-danger"
                className="test-and-tag-modal-footer-buttons"
                id="close-modal-btn"
                style={{
                  borderRadius: "0",
                  border: "2px solid",
                  textTransform: "uppercase",
                }}
                onClick={props.handleClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TestAndTagModal;
