import React, { useState, useEffect } from 'react';

//IMPORTED BOOTSTRAP COMPONENTS
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

//IMAGES
import ctatLogo from '../../images/ctat.png';


const NavigationBar = (props) => {

    //STATE for handling Navigation Bar background Change on Sroll event.
    const [blackNavigation, setBlackNavigation] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;

            if (scroll >= 100) {
                setBlackNavigation(true);
            }

            if (scroll <= 100) {
                setBlackNavigation(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [blackNavigation]);

    const testAndTagModalHandler = () => {
      setExpanded(false);
    }



    return (
        <div className='main-navigation-container'>
          {['lg'].map((expand) => (
            <Navbar key={expand}  expand={expand} variant="light" className={blackNavigation ? "mb-3 navigation-container black-navigation" : "mb-3 navigation-container"} fixed='top' expanded={expanded}>
              <Container fluid className='navigation-content-container'>
                <Navbar.Brand href="#home" className='main-navigation-logo-container'><img className='logo' src={ctatLogo} alt='ciscal-logo'/></Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="navigation-bar-toggler" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                  className="navigation-offcanvas"
                  scroll="true"
                  onHide={() => setExpanded(false)}
                >
                  <Offcanvas.Header closeButton onClick={() => setExpanded(false)} className="navigation-offcanvas-header">
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className="navigation-offcanvas-title" style={{textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.5rem'}}>Menu</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className='navigation-offcanvas-body'>
                    <Nav className="justify-content-end flex-grow-1 pe-3 navigation-offcanvas-links-container">
                      <Nav.Link href="#home" className='navigation-offcanvas-links' style={{textTransform: 'uppercase'}} onClick={() => setTimeout(() => {setExpanded(false)}, 400)}>Home</Nav.Link>
                      <Nav.Link className='navigation-offcanvas-links' style={{textTransform: 'uppercase'}} onClick={ () => {props.handleShow(); testAndTagModalHandler()}  }>Schedule Test and Tag</Nav.Link>
                      <Nav.Link href="#contact-us" className='navigation-offcanvas-links' style={{textTransform: 'uppercase'}} onClick={() => setTimeout(() => {setExpanded(false)}, 400)}>Contact Us</Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </div>
      );
};

export default NavigationBar;

