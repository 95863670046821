//INITIAL STATE
let initialState = {
    productName: "",
    companyName: "",
    contactPerson: "",
    emailAddress: "",
    phoneNumber: "",
    Message: "",
    equipmentToBeServiced: "",
    dateOfRequest: "",
};

function FormReducer(state, action) {
    switch (action.type) {
        case "HANDLE CONTACT FORM INPUT":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE REQUEST TEST AND TAG FORM INPUT":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE FORM RESET":
            return initialState;
        default:
            return state;
    }
}


export default FormReducer;