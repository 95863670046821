import React, { useReducer } from "react";
import axios from "axios";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import formReducer from "../reducers/FormReducer";

let initialContactFormState = {
  companyName: "",
  contactPerson: "",
  phoneNumber: "",
  emailAddress: "",
  Message: "",
};

const ContactForm = (props) => {
  let [state, dispatch] = useReducer(formReducer, initialContactFormState);

  const handleTextChange = (event) => {
    dispatch({
      type: "HANDLE CONTACT FORM INPUT",
      field: event.target.name,
      payload: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    let data = state;
    //console.log(data);
    sendContactForm(data);
    event.preventDefault();
  };

  const sendContactForm = (data) => {
    axios
      .post("https://mailer.ciscientific.com/ctat-contact-form", data)
      .then((response) => {
        let result = response.data;
        if (
          result.status === "Message Sent" ||
          result.status === "Message Recieved"
        ) {
          console.log("Email Sent!");
          //props.notifyToastSuccess();
          props.handleModalPopup();
          props.handleMessageStatus(true);
          formReset();
        } else {
          console.log("Error");
          //props.notifyToastFailed();
          props.handleModalPopup();
          props.handleMessageStatus(false);
        }
        //console.log(result.data);
      });
  };

  // RESET FORM FUNCTION
  const formReset = () => {
    dispatch({ type: "HANDLE FORM RESET" });
  };

  return (
    <div className="contact-form-container" id="contact-us">
      <h4 className="title-header">Contact Us</h4>
      <p className="title-header-paragraph">
        Get in touch with us regarding your concerns by filling out the form
        below. Our team will be happy to hear from you!
      </p>

      <form className="request-form" method="POST" onSubmit={handleFormSubmit}>
        <div className="form-container">
          <div id="form-field-items">
            <TextField
              type="text"
              name="companyName"
              value={state.companyName}
              className="contact-form-field"
              id="company-name"
              label="Company Name"
              variant="filled"
              margin="dense"
              sx={{ backgroundColor: "#FFFFFF", border: "1", width: "100%" }}
              required
              onChange={handleTextChange}
            />

            <TextField
              type="text"
              name="contactPerson"
              value={state.contactPerson}
              className="contact-form-field"
              id="contact-person"
              label="Contact Person"
              variant="filled"
              margin="dense"
              sx={{ backgroundColor: "#FFFFFF", border: "1", width: "100%" }}
              required
              onChange={handleTextChange}
            />

            <TextField
              type="number"
              name="phoneNumber"
              value={state.phoneNumber}
              className="contact-form-field"
              id="contact-number"
              label="Contact Number"
              variant="filled"
              margin="dense"
              sx={{ backgroundColor: "#FFFFFF", border: "1", width: "100%" }}
              required
              onChange={handleTextChange}
            />

            <TextField
              type="email"
              name="emailAddress"
              value={state.emailAddress}
              className="contact-form-field"
              id="email-address"
              label="Email Address"
              variant="filled"
              margin="dense"
              sx={{ backgroundColor: "#FFFFFF", border: "1", width: "100%" }}
              required
              onChange={handleTextChange}
            />

            <TextField
              type="text"
              name="Message"
              value={state.Message}
              className="contact-form-field"
              id="message message-box"
              label="Message"
              variant="filled"
              multiline
              rows={5}
              margin="dense"
              sx={{ backgroundColor: "#FFFFFF", border: "1", width: "100%" }}
              onChange={handleTextChange}
            />
          </div>
        </div>

        <Button
          className="request-btn"
          variant="outlined"
          color="primary"
          size="large"
          name="submit"
          value="submitRequest"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
