import React from 'react';

//IMAGES
import FooterLogo from '../../images/ctat.png';

//MATERIAL UI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = (props) => {
    return (
        <div className='footer-container'>
            <section className='footer-content-container'>
                <div className='footer-main-content'>
                    <div className='panel-1'>
                        <div className='footer-box-1'>
                            <div className='footer-logo-container'>
                                <img src={FooterLogo} alt='ciscal test and tag logo' className='footer-logo' />
                            </div>
                            <p className='footer-pitch-text'>
                                Make sure your equipment is safe to use and maintained to it’s fullest capacity. CISCAL Test and Tag can provide this for you. 
                                Click on the button below to schedule a Testing and Tagging Service!
                            </p>
                            <Button variant="outlined" size="large" className='footer-button' fullWidth onClick={props.handleShow}>Schedule Test & Tag Now</Button>
                        </div>
                    </div>
                    <div className='panel-2'>
                        <div className='footer-box-2'>
                            <h4 className='footer-text-header'>OFFICE ADDRESS</h4>
                            <p className='footer-text'>
                                <span className='footer-office'>
                                    NSW Office (Head Office)
                                </span>
                                Unit 1/9 Bearing Road, Seven Hills NSW 2147 Australia.
                            </p>

                            <p className='footer-text'>
                                <span className='footer-office'>
                                    VIC Office
                                </span>
                                Unit 13/63 Ricky Way, Epping VIC 3076 Australia.
                            </p>

                            <p className='footer-text'>
                                <span className='footer-office'>
                                    QLD Office
                                </span>
                                Unit 3 54-58 Nealdon Drive Meadowbrook, QLD, 4131 Australia.
                            </p>
                        </div>
                        <div className='footer-box-3'>
                            <h4 className='footer-text-header'>OFFICE HOURS</h4>
                            <p className='footer-text'>
                                <span className='footer-office'>
                                    Weekdays
                                </span>
                                8:00 AM to 4:30 PM
                            </p>

                            <p className='footer-text'>
                                <span className='footer-office'>
                                    CONTACT US
                                </span>
                                <span className='footer-office-text'><a href='tel:1300-225-542'>1300-225-542</a></span>
                                <span className='footer-office-text'><a href='mailto:info@ciscientific.com'>info@ciscientific.com</a></span>
                                <span className='footer-office-text'><a href='https://www.ciscaltech.com/customer-feedback' target='_blank' rel='noreferrer'>Customer Feedback</a></span>

                            </p>
                            <div className='footer-social-icons-container'>
                            <IconButton href='https://web.facebook.com/ciscientific/' target={'_blank'} aria-label="facebook" className='footer-icon-buttons'><FacebookIcon className='social-icons' sx={{color: '#FFFFFF'}} /></IconButton>
                            <IconButton href='https://www.instagram.com/ci_scientific/' target={'_blank'} aria-label="instagram" className='footer-icon-buttons'><InstagramIcon className='social-icons' sx={{color: '#FFFFFF'}} /></IconButton>
                            <IconButton href='https://www.youtube.com/user/CIScientific' target={'_blank'} aria-label="youtube" className='footer-icon-buttons'><YouTubeIcon className='social-icons' sx={{color: '#FFFFFF'}} /></IconButton>
                            <IconButton href='https://www.linkedin.com/company/ci-scientific-pty-ltd/' target={'_blank'} aria-label="linkedin" className='footer-icon-buttons'><LinkedInIcon className='social-icons' sx={{color: '#FFFFFF'}} /></IconButton>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className='footer-copyright-text'>
                    <p className='footer-office'>© Copyright CI Scientific Pty Ltd. 2019-2023. All Rights Reserved.</p>
                </div>
            </section>
        </div>
    );
};

export default Footer;