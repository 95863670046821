import React from 'react';

//IMAGE
import IntroductionImage from '../../images/qualified-electricians.webp';

const IntroductionSection = () => {
    return (
        <div className='introduction-section-container'>
            <section className='introduction-section-content-container'>
                <div className='introduction-section-image-container'><img src={IntroductionImage} alt="qualified electricians" className='introduction-section-image' /></div>
                <div className='introduction-section-texts-container'>
                    <h2 className='introduction-section-header'>Qualified and Trusted Testing and Tagging Service in Australia</h2>
                    <p className='introduction-section-texts'>
                        CISCAL Test and Tag follows the Australian Standard when it comes to handling testing  and tagging services in businesses and in household. 
                        We make sure that strict compliance is continously implemented to make sure the safety and peace of mind of our customers. 
                        Our well trained technicians are equipped with the right tools and knowledge to perform testing and tagging tasks in a fast and efficient manner. 
                        You can rely on us providing you with an excellent service from start of the project to it's end.
                    </p>
                </div>
            </section>
        </div>
    );
};

export default IntroductionSection;