import React, { useState } from 'react';
import './App.css';

//COMPONENTS
import NavigationBar from './views/components/NavigationBar';
import TopBanner from './views/components/TopBanner';
import BottomBanner from './views/components/BottomBanner';
import BodyTaglineSection from './views/components/BodyTaglineSection';
import CardsSection from './views/components/CardsSection';
import IntroductionSection from './views/components/IntroductionSection';
import ImportanceOfTestAndTag from './views/components/ImportanceOfTestAndTag';
import Footer from './views/components/Footer';
import ContactForm from './views/components/ContactForm';
import TestAndTagModal from './views/components/TestAndTagModal';
import ConfirmationModal from './views/components/confirmationModal';
//import HolidayBreakModal from './views/components/HolidayBreakModal';

// REACT GOOGLE ANALYTICS
import ReactGoogleAnalytics  from 'react-ga4';

// INITIALIZATION OF REACT GOOGLE ANALYTICS
ReactGoogleAnalytics.initialize([{trackingId: "G-SRJ3G8SNLN"}]);
ReactGoogleAnalytics.send({ hitType: "pageview", page: window.location.pathname });

function App() {

  //STATES and FUNCTIONS to handle the Request Test and Tag Modal Form and Contact Form
    const [show, setShow] = useState(false);
    const [modalPopUp, setModalPopUp] = useState(false);
    const [messageStatus, setMessageStatus] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // codes for the confirmationModal
    const hanldeModalClose = () => setModalPopUp(false);
    const handleModalPopup = () => setModalPopUp(true);
    const handleMessageStatus = (value) => setMessageStatus(value);

    //GOOGLE ANALYTICS CUSTOM EVENTS

  //custom events for buttons
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGoogleAnalytics.event({
      category: category,
      action: action,
      label: label,
    })
  };

  return (
    <div className="App">
      <NavigationBar 
        handleShow = { handleShow }
        eventTrack={ eventTrack }
      />
      <TopBanner 
        handleShow = { handleShow }
        eventTrack={ eventTrack }
      />
      <BottomBanner 
        eventTrack={ eventTrack }
      />
      <IntroductionSection />
      <CardsSection />
      <BodyTaglineSection />
      <ImportanceOfTestAndTag />
      <ContactForm 
        handleModalPopup = { handleModalPopup }
        hanldeModalClose = { hanldeModalClose }
        handleMessageStatus = { handleMessageStatus }
        eventTrack={ eventTrack }
      />
      <Footer 
        handleShow = { handleShow }
        eventTrack={ eventTrack }
      />
      <TestAndTagModal 
        show = { show }
        handleClose = { handleClose }
        handleModalPopup = { handleModalPopup }
        hanldeModalClose = { hanldeModalClose }
        handleMessageStatus = { handleMessageStatus }
        eventTrack={ eventTrack }
      />
      <ConfirmationModal 
        modalPopUp = { modalPopUp }
        messageStatus = { messageStatus}
        handleModalPopup = { handleModalPopup }
        hanldeModalClose = { hanldeModalClose }
        handleMessageStatus = { handleMessageStatus }
      />

      {/* <HolidayBreakModal /> */}
    </div>
  );
}

export default App;
