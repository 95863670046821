import React from 'react';

// ICON IMAGES
import WellTrainedTechnicians from '../../images/well-trained-technicians.webp';
import ProfessionalTestingAndTagging from '../../images/professional-testing-and-tagging.webp';
import FastAndReliableService from '../../images/fast-and-reliable-service.webp';

const CardsSection = () => {
    return (
        <div className='cards-section-main-container'>
            <section className='cards-section-container'>
                
                <div className='cards-section-cards'>
                    <div className='cards-section-card-content-container'>
                        <div className='cards-image-container'>
                            <img src={WellTrainedTechnicians} alt='cards-icon' className='cards-icon' />
                        </div>
                        <div className='card-texts-container'>
                            <h3 className='cards-title'>Well Trained Technicians</h3>
                            <p className='cards-texts'>
                                Our technicians are licensed and well-trained to provide the services you need, ensuring safety and peace of mind.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='cards-section-cards'>
                    <div className='cards-section-card-content-container'>
                        <div className='cards-image-container'>
                            <img src={ProfessionalTestingAndTagging} alt='cards-icon' className='cards-icon' />
                        </div>
                        <div className='card-texts-container'>
                            <h3 className='cards-title'>Professional Testing & Tagging</h3>
                            <p className='cards-texts'>
                                We follow strict compliance in accordance with the Australian regulations on handling electrical appliances and equipments.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='cards-section-cards'>
                    <div className='cards-section-card-content-container'>
                        <div className='cards-image-container'>
                            <img src={FastAndReliableService} alt='cards-icon' className='cards-icon' />
                        </div>
                        <div className='card-texts-container'>
                            <h3 className='cards-title'>Fast & Reliable Service</h3>
                            <p className='cards-texts'>
                                Our staff are trained to deal with all kinds of situations ensuring a fast, reliable, and trust-worthy service.
                            </p>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default CardsSection;