import React from 'react';

//IMAGES
import CIS from '../../images/ci-scientific.webp';
import GFA from '../../images/gas-fill-australia.webp';
import MSM from '../../images/my-sparky-mate.webp';
import CTAT from '../../images/ciscal-test-and-tag.webp';
import CAA from '../../images/ciscal-autoclave.webp';
import AIS from '../../images/active-instrument.webp';
import CIW from '../../images/ciscal-weighing.webp';


const BottomBanner = () => {
    return (
        <div className='bottom-banner-container'>
            <section className='bottom-banner-content-container'>
                <div className='bottom-banner-contact-container'>
                    <div className='banner-bottom-office-hours'>
                        <h2 className='banner-bottom-contact-title'>Office Hours</h2>
                        <p className='banner-bottom-contact-text'>Weekdays | 8:00 am to 4:30 pm</p>
                    </div>
                    <div className='banner-bottom-telephone-email'>
                        <h2 className='banner-bottom-contact-title'>Telephone and Email</h2>
                        <p className='banner-bottom-contact-text'>1300-225-542</p>
                        <p className='banner-bottom-contact-text'>info@ciscientific.com</p>
                    </div>
                </div>
                <div className='bottom-banner-division-container'>
                    <p className='banner-bottom-contact-text'>
                        We are part of a group of companies offering high-quality products and specialized services from testing and tagging, weighing, 
                        sterilizations, calibration, and gas filling products to laboratory and analyzation equipment.
                    </p>
                    <div className='bottom-banner-division-image-container'>
                        <a href='https://ciscientific.com/' target={"_blank"} rel="noreferrer"><img src={CIS} alt='ci scientific' className='bottom-banner-division-image'/></a>
                        <a href='https://gasfillaustralia.com.au/' target={"_blank"} rel="noopener noreferrer"><img src={GFA} alt='gas fill australia' className='bottom-banner-division-image'/></a>
                        <a href='https://www.mysparkymate.com.au/' target={"_blank"} rel="noopener noreferrer"><img src={MSM} alt='my sparky mate' className='bottom-banner-division-image'/></a>
                        <a href='https://ciscaltestandtag.com.au/' target={"_blank"} rel="noopener  noreferrer"><img src={CTAT} alt='ciscal test and tag' className='bottom-banner-division-image'/></a>
                        <a href='https://ciscalautoclavesaustralia.com.au/' target={"_blank"} rel="noopener  noreferrer"><img src={CAA} alt='autoclave australia' className='bottom-banner-division-image'/></a>
                        <a href='https://www.active-instruments.com.au/' target={"_blank"} rel="noopener  noreferrer"><img src={AIS} alt='active instrument services' className='bottom-banner-division-image'/></a>
                        <a href='https://ciscalweighing.com.au/' target={"_blank"} rel="noopener  noreferrer"><img src={CIW} alt='ciscal weighing' className='bottom-banner-division-image'/></a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BottomBanner;