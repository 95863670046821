import React from 'react';

//IMAGES
import TestAndTagImportanceImage from '../../images/importance-of-test-and-tag2.webp';

const ImportanceOfTestAndTag = () => {
    return (
        <div className='test-and-tag-importance-main-container'>
            <section className='test-and-tag-importance-content-container'>
                <div className='test-and-tag-importance-text-container'>
                    <div className='test-and-tag-importance-text-content' id='content-1'>
                        <h4 className='test-and-tag-importance-text-content-header'>Why you should Test and Tag your Electrical Tools?</h4>
                        <p className='test-and-tag-importance-text-content-texts'>
                            Testing and tagging electrical tools is an important safety measure that can help prevent accidents and injuries in the workplace. 
                            Here are a few reasons why you should test and tag your electrical tools:
                        </p>
                        <ul className='test-and-tag-importance-text-content-list'>
                            <li className='test-and-tag-importance-text-content-list-items'>
                                <span className='list-item-title'>Compliance with regulations:</span> In many countries, employers are required by law to ensure that their electrical tools are safe for use.
                            </li>
                            <li className='test-and-tag-importance-text-content-list-items'>
                                <span className='list-item-title'>Risk management:</span> Electrical tools can be a source of accidents and injuries if they are not properly maintained. 
                            </li>
                            <li className='test-and-tag-importance-text-content-list-items'>
                                <span className='list-item-title'>Protect your employees:</span> Electrical tools can be dangerous if they are not used properly or if they are damaged.
                            </li>
                            <li className='test-and-tag-importance-text-content-list-items'>
                                <span className='list-item-title'>Protect your business:</span> Accidents and injuries can result in lost productivity, increased insurance premiums, and legal liability.
                            </li>
                        </ul>
                        <p className='test-and-tag-importance-text-content-texts'>
                            Overall, testing and tagging electrical tools is an important step in maintaining a safe and compliant workplace. 
                            By taking the time to regularly test and tag your tools, you can help protect your employees, your business, and yourself.
                        </p>

                        <h4 className='test-and-tag-importance-text-content-header'>Prevent the use of faulty equipment or appliances</h4>
                        <p className='test-and-tag-importance-text-content-texts'>
                            Using faulty equipment or appliances can be dangerous and can lead to accidents and injuries. 
                            Our Test and Tag technicians will properly tag your faulty equipment as well as your good machines by putting the date and the name of the tester. 
                            The tags are also color-coded according to the level of risk.

                            The faulty machines are either removed from use, replaced, or fixed by our technicians and we make sure that the machine is in excellent 
                            operating condition before using.
                        </p>
                    </div>
                </div>

                <div className='test-and-tag-importance-image-content'>
                    <img src={TestAndTagImportanceImage} alt='importance of test and tag' className='test-and-tag-importance-image'/>
                </div>
            </section>
        </div>
    );
};

export default ImportanceOfTestAndTag;