import React from 'react';
import Button from '@mui/material/Button';

const TopBanner = (props) => {
    return (
            <div className='top-banner-container' id='home'>
                <section className='top-banner-content-container'>
                    <div className='top-banner-text-container'>
                        <p className='top-banner-tag-line'>Professional testing and tagging services in australia</p>
                        <h1 className='top-banner-header-text'>CISCAL <span className='test-and-tag-header-text'>Test & Tag</span></h1>
                    </div>
                    <div className='top-banner-button-container'>
                        <Button variant="outlined" size="large" className='top-banner-button' onClick={props.handleShow}>Schedule Test & Tag</Button>
                        <Button href="#contact-us" variant="outlined" size="large" className='top-banner-button'>Contact Us</Button>
                    </div>
                </section>
            </div>
    );
};

export default TopBanner;