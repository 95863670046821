import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import InfoIcon from '@mui/icons-material/Info';


const confirmationModal = (props) => {
  return (
    <Modal
    show = {props.modalPopUp}
    onHide={props.hanldeModalClose}
    size="sm"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    
    //style={{minWidth: '200px', maxWidth: '300px'}}
  >
    {props.messageStatus ? <CheckCircleTwoToneIcon style={{width: '100px', height: '100px', color: '#2fd064', margin: '10px auto'}} /> : <CancelTwoToneIcon style={{width: '100px', height: '100px', color: '#d92c26', margin: '10px auto'}} />}
    <Modal.Body style={{textAlign: 'center', padding: '0 20px', fontSize: '100px'}}>
      <h4 style={{fontSize: '25px'}}>{props.messageStatus ? "Message Sent!" : "Message Not Sent"}</h4>
      <p style={{fontSize: '20px'}}>
      {props.messageStatus 
      ? "Your request has been recieved. Please expect a confirmation email on your email address." 
      : "We encountered an Error. Please try again later!"}
      </p>
    </Modal.Body>
    <Modal.Footer style={{flexDirection: 'column'}}>
        {props.messageStatus ? <span style={{fontSize: '12px', display: 'block', textAlign: 'center', margin: '0 auto 10px auto', color: 'rgba(100, 100, 100, 1)'}}><InfoIcon style={{width: '30px', height: '30px', color: '#36c6c9', display: 'block', margin: '0 auto', marginBottom: '10px'}} /> check your <strong>SPAM</strong> or <strong>JUNK</strong> folder if the email is not on your mailbox.</span> : ""}
      <Button onClick={props.hanldeModalClose} style={{backgroundColor: '#35448e', border: 'none'}}>Close</Button>
    </Modal.Footer>
  </Modal>
);
};

export default confirmationModal;
