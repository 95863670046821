import React from 'react';

const BodyTaglineSection = () => {
    return (
        <div className='body-tagline-section-container'>
            <section className='body-tagline-section-content-container'>
                <div className='body-tagline-container'>
                    <h4 className='body-tagline'> We Offer Professional Testing and Tagging Services Across Australia </h4>
                    <div className='color-bar'></div>
                </div>

                <div className='body-tagline-text-container'>
                    <div className='body-tagline-text-box'>
                        <p className='body-tagline-text'>
                            Our company has a history of working with major clients who have well-known brands. 
                            We assure you that the level of trust and care we provide to these clients is the same that we offer to all businesses 
                            who require reliable testing and tagging services. Rest assured that your company will receive the same high level of service that our 
                            major clients have come to expect from us.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BodyTaglineSection;